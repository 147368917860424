<template>
  <div class="main">
    <div class="main-top">
      <img @click="goBack" src="@/assets/image/arrow-left.png" />
      <span>修改密码</span>
    </div>
    <div class="content-fa" v-if="userid">
      <div class="main-content">
        <div class="input-box">
          <input v-model="oldPassword" placeholder="原密码" :type="type1" />
          <img
            class="eyes"
            @click="type1 = 'text'"
            v-if="type1 === 'password'"
            src="@/assets/image/close-eyes.png"
          />
          <img
            class="eyes"
            @click="type1 = 'password'"
            v-if="type1 === 'text'"
            src="@/assets/image/open-eyes.png"
          />
        </div>

        <div class="input-box">
          <input v-model="newPassword" placeholder="新密码" :type="type2" />
          <img
            class="eyes"
            @click="type2 = 'text'"
            v-if="type2 === 'password'"
            src="@/assets/image/close-eyes.png"
          />
          <img
            class="eyes"
            @click="type2 = 'password'"
            v-if="type2 === 'text'"
            src="@/assets/image/open-eyes.png"
          />
        </div>

        <div class="input-box">
          <input
            v-model="confirmedPassword"
            placeholder="确认密码"
            :type="type3"
          />
          <img
            class="eyes"
            @click="type3 = 'text'"
            v-if="type3 === 'password'"
            src="@/assets/image/close-eyes.png"
          />
          <img
            class="eyes"
            @click="type3 = 'password'"
            v-if="type3 === 'text'"
            src="@/assets/image/open-eyes.png"
          />
        </div>
        <div class="tips">
          温馨提示：为了保障您的账号安全，请设置长度较长，以及包含字母、数字、字母的密码。
        </div>
      </div>
      <div :class="['btn2']" @click="update">确认修改</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      userid: null,
      oldPassword: "",
      newPassword: "",
      confirmedPassword: "",
      type1: "password",
      type2: "password",
      type3: "password"
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    LogOut() {
      localStorage.clear();
      this.$router.push("/mobile");
    },
    update() {
      if (!this.oldPassword) {
        this.$toast("原密码不能为空");
        return;
      }
      if (!this.newPassword) {
        this.$toast("新密码不能为空");
        return;
      }
      if (!this.confirmedPassword) {
        this.$toast("确认密码不能为空");
        return;
      }
      if (this.newPassword !== this.confirmedPassword) {
        this.$toast("新密码与确认密码不一致");
        return;
      }

      this.$http
        .post(`api/v1/Accounts/${this.userid}/Password`, null, {
          params: {
            oldPassword: this.oldPassword,
            newPassword: this.newPassword,
            confirmedPassword: this.confirmedPassword,
            id: this.userid
          }
        })
        .then(async res => {
          if (res.status === 200) {
            this.$toast("修改成功");
            localStorage.clear();
            this.$router.push("/mobile");
          } else {
            this.$toast(res.statusText);
          }
        });
    }
  },
  async created() {
    if (localStorage.getItem("userid")) {
      this.userid = localStorage.getItem("userid");
    } else {
      this.$router.push("/mobile");
    }
  }
};
</script>
<style lang="scss" scoped>
.main {
  min-height: calc(100vh - 40px);
  width: 100vw;
  background-color: #f8f8fa;
  position: relative;
  padding-top: 40px;
  .main-top {
    position: fixed;
    top: 0;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #232323;
    font-size: 16px;
    font-weight: bolder;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    background-color: #fff;
    img {
      width: 20px;
      position: absolute;
      left: 0;
      margin-left: 15px;
    }
  }
  .main-content {
    padding: 0 15px;
    box-sizing: border-box;
    width: 100%;
    .input-box {
      position: relative;
      width: 100%;
      height: 46px;
      margin-top: 14px;
      .eyes {
        position: absolute;
        width: 24px;
        height: 24px;
        top: 50%;
        transform: translateY(-50%);
        right: 12px;
      }
    }
    input {
      width: 100%;
      height: 46px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      border: none;
      outline: none;
      padding: 0 48px 0 12px;
      box-sizing: border-box;
      font-size: 14px;
    }
    .tips {
      color: #fa5151;
      font-size: 16px;
      margin-top: 20px;
    }
  }
  .content-fa {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 40px);
    .btn2 {
      width: calc(100% - 30px);
      height: 46px;
      background: linear-gradient(138deg, #ffa200 0%, #ff6017 100%);
      border-radius: 25px 25px 25px 25px;
      text-align: center;
      line-height: 46px;
      color: #ffffff;
      font-size: 18px;
      box-sizing: border-box;
      margin: 40px 15px;
    }
    .btn-opacity {
      opacity: 0.6;
    }
  }
}
</style>
